var blueimp = require('blueimp-gallery/js/blueimp-gallery.min');
var links = document.getElementById('links');

if(links != null) {
    links.onclick = function (event) {
        event = event || window.event;
        var target = event.target || event.srcElement;
        var link = target.src ? target.parentNode : target;
        var options = { index: link, event: event };
        var links = this.getElementsByTagName('a');
        blueimp(links, options);
    };
}
